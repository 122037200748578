import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import BlogAside from "../blog/blog-aside/BlogAside";
import blog1 from "../../assets/img/product-detail/fertilizer-application1.jpg";
import BlogCommentForm from "./blog-comment-form/BlogCommentForm";
import BlogUsersComment from "./blog-users-comment/BlogUsersComment";
import { Helmet } from "react-helmet";
import { useGetBlogDetailsQuery } from "../products/productSlice";
import { useParams } from "react-router-dom";

function BlogDetail() {
  const params = useParams()
  const { data, isLoading } = useGetBlogDetailsQuery(params.id)
  return (
    <>
      <Helmet>
        <title>Blog Detail | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Breadcrumb title="Blog Detail " />
      <section className="blogDetailSec p-30">
        <div className="container">
          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
          <div className="row">
            <div className="col-md-8">
              <div className="blogDetailInfo">
                <div className="blogDetailContent">
                  <figure className="currentBlogImg">
                    <img src={blog1} alt="Blog" className="img-fluid" />
                  </figure>
                  <h4>{data?.title}</h4>
                  <p>
                    {data?.description} ,
                    dolor in sollicitudin faucibus, sem massa accumsan
                    erat.
                  </p>
                  <strong className="mb-3 d-block">
                    {data?.short_description}“ sometimes on purpose ”
                  </strong>
                  <p>
                    Aenean lorem diam, venenatis nec venenatis id, adipiscing ac
                    massa. Nam vel dui eget justo dictum pretium a rhoncus
                    ipsum. Donec venenatis erat tincidunt nunc suscipit, sit
                    amet bibendum lacus posuere. Sed scelerisque, dolor a
                    pharetra sodales, mi augue consequat sapien, et interdum
                    tellus leo et nunc. Nunc imperdiet eu libero ut imperdiet.
                  </p>
                </div>
                <BlogUsersComment />
                <BlogCommentForm />
              </div>
            </div>
            <div className="col-md-4">
              <BlogAside />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
