import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { Table } from "react-bootstrap"

function EarningPoints() {

    const userid = window.localStorage.getItem("user_id");
    const [data, setData] = useState()

    const getData = async () => {
        try {
            const res = await axios.get(`https://onlineparttimejobs.in/api/clubPointsUser/public/${userid}`)
            setData(res.data)
        } catch (error) {
            alert('Server Error Failed To load Data')
        }
    }

    useEffect(() => {
        getData()
    }, [])


    return <div className="container">
        <div className="row card" style={{ padding: "10px", margin: "10px auto" }}>
            <h5>My Points</h5>
            <div className="col-md-7 mx-auto" style={{ margin: "15px 0" }}>
                <div className="bg-grad-1 text-white rounded-lg overflow-hidden" style={{ backgroundImage: 'linear-gradient(315deg, #eb4786 0%, #b854a6 74%)', borderRadius: "10px" }}>
                    <div className="px-3 pt-3 pb-3">
                        <div className="h3 fw-700 text-center">{data?.Points} Points = ZK1.00 Wallet Money</div>
                        <div className="opacity-50 text-center">Exchange Rate</div>
                    </div>
                </div>
            </div>
            <h6>Point Earning history</h6>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order Code</th>
                        <th>Points</th>
                        <th>Product Qty</th>
                        <th>Type</th>
                        <th>Date</th>
                        {/* <th>Status</th> */}
                    </tr>
                </thead>
                <tbody>
                    {data && data?.map((item, i) => {
                        return <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item?.order_id?.order_referenceNo}</td>
                            <td>{item?.point}</td>
                            <td>{item?.product_qty}</td>
                            <td>@mdo</td>
                            <td>@mdo</td>
                            {/* <td>@mdo</td> */}
                        </tr>
                    })}
                </tbody>
            </Table>
        </div>
    </div>
}
export default EarningPoints