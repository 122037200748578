import React, { useEffect, useState } from "react";
import { GrFacebookOption } from "react-icons/gr";
import { AiOutlineTwitter, AiOutlineInstagram, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { TbBrandWhatsapp, TbJewishStarFilled } from "react-icons/tb";
import { FaBabyCarriage, FaShippingFast, FaUser } from "react-icons/fa";
import "./TopHeader.css";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdOutlineArrowDropDown, MdSell } from "react-icons/md";
import { BiLogInCircle, BiUser } from "react-icons/bi";
import { SiAdobecreativecloud } from "react-icons/si";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useChangeCurrencyMutation, useChangeLanguageMutation, useGetCurrencyQuery, useGetLanguageQuery, useGetUserDetailQuery, usePostCartOfflineMutation } from "../../../components/products/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdatedProduct } from "../../../components/products/productSlice";
import img from "../../../assets/img/client/2.jpg"
import { BsBuildingAdd } from "react-icons/bs";
import { CgTrack } from "react-icons/cg";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { GiNewspaper, GiWallet } from "react-icons/gi";

function TopHeader({ state }) {
  const isLogin = window.localStorage.getItem("isLogin");
  const user_id = window.localStorage.getItem("user_id");
  const userName = window.localStorage.getItem("userName");
  const profileImg = window.localStorage.getItem("profilePic");
  const [user, setUser] = useState()
  const navigate = useNavigate();

  const [changeCurr, { isLoading: curLoad }] = useChangeCurrencyMutation()
  const [changeLang, { isLoading: langLoad }] = useChangeLanguageMutation()

  const { updatedProducts } = useSelector((state) => {
    return state.productList
  })


  const { data: language } = useGetLanguageQuery()
  const { data: currency } = useGetCurrencyQuery()
  const dispatch = useDispatch()
  const logout = () => {
    window.localStorage.setItem("isLogin", false)
    window.localStorage.setItem("user_id", '')
    window.localStorage.setItem("userName", '')
    window.localStorage.setItem("profilePic", false)
    dispatch(clearUpdatedProduct())
    navigate("/login");
  };
  const { data, isSuccess } = useGetUserDetailQuery(user_id)

  useEffect(() => {
    setUser(data)
  }, [data])


  const onchengeHandle = (e) => {
    if (e.target.id === 'languId') {
      const obj = { userid: user_id, language: e.target.value }

      changeLang(obj)

    } else {
      const obj2 = { userid: user_id, currency: e.target.value }

      changeCurr(obj2)
    }

  }

  return (
    <>
      <div className="topHeaderSec" id="topHead">
        <div className="container">
          <div className="topHeaderInfo">

            <div className="leftSide">
              <ul>
                <li style={{marginLeft:"0"}}>Tool Online App</li>
                <li>Super opportunity</li>
                <li>Last chance</li>
                <li>services</li>
                <li>Staff</li>
                <li>Tool Online Club</li>
                <li>Business Solutions</li>
              </ul>
            </div>

            <div className="rightList">

              <div className="miscel">
                <div className="socialMedia">
                  <ul>

                    {isLogin === 'true' && (
                      <li>
                        <button
                          type="button"
                          className="logoutBtn"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </li>
                    )}
                    {isLogin === 'false' && (
                      <li>
                        <Link to="/login">Login </Link>
                      </li>
                    )}
                    {isLogin === 'true' && <li style={{ background: "white", borderRadius: "100%", marginRight: "-10px", overflow: "hidden" }}>
                      {/* <AiOutlineUser /> */}
                      {profileImg === 'false' ? <AiOutlineUser style={{ width: "50px", height: "50px" }} /> : <img style={{ width: "60px", height: "60px" }} src={profileImg} />}

                    </li>}


                    {isLogin === 'true' && (
                      <li className="userNamePro">
                        <Link to="/" className="userNameText" >
                          {userName}  <MdOutlineArrowDropDown style={{ fontSize: "19px" }} />
                        </Link>
                        <ul className="dropdown-menu helll" >
                          <li><AiOutlineShoppingCart /> <Link to="/myAccount">My Oders</Link></li>
                          <li><TbJewishStarFilled /> <Link to="/wishlist">My WishList</Link></li>
                          <li><FaBabyCarriage /> <Link to="/cart">My Cart</Link></li>
                          <li><GiWallet /> <Link to="/myWallet">My Wallet</Link></li>
                          <li><GiNewspaper /> <Link to="/my_product_reviews">My Product Reviews  </Link></li>
                          <li><VscActivateBreakpoints /> <Link to="/earning-points">My Earning Points</Link></li>
                          <li><HiOutlineReceiptRefund /> <Link to="/sent-refund-request">Sent Refund Request</Link></li>
                          <li><BsBuildingAdd /> <Link to="/billingAddress">My Billing Address</Link></li>
                          <li><FaShippingFast /> <Link to="/shippingAddress">My Shipping Address</Link></li>
                          <li><MdSell /> <Link to="/allSeller">Visit Sellers</Link></li>
                          {/* <li><SiAdobecreativecloud /> <Link to="/seller/sign-Up">Become ETG Seller</Link></li> */}
                          <li><SiAdobecreativecloud /> <Link to="/rma_history">RMA History</Link></li>
                          <li><BiUser /> <Link to="/profile">My Profile</Link></li>
                          <li><BiUser /> <Link to="/changePassword">Change Password</Link></li>
                          <li><CgTrack /> <Link to="/track-order">Track My Order</Link></li>
                          <li><MdSell /> <Link to="/support_ticket">Support Ticket</Link></li>
                          {isLogin === 'true' && <li onClick={logout}><BiLogInCircle /> Logout</li>}
                          {isLogin === 'false' && <li><BiLogInCircle /> <Link to="/login">Login </Link></li>}
                        </ul>
                      </li>
                    )}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default TopHeader;
