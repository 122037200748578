import React from "react";
import { useGetPrivacyPolicyQuery } from "../products/productSlice";

function PrivacyPolicy() {

  const { data, isLoading } = useGetPrivacyPolicyQuery()
  console.log(data);
  return (
    <>
      <section className="privacySec p-30">
        <div className="container">
          <div className="privacyInfo">
            <div className="privacyTitle mb-3">
              {isLoading && <div className="preloaderCount">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>}
              <h1>Privacy Policy</h1>
              <p>
                We value the trust you place in us. That's why we insist upon
                the highest standards for secure transactions and customer
                information privacy. Please read the following statement to
                learn about our information gathering and dissemination
                practices.
              </p>
            </div>

            <div className="privacyNote">
              <strong>Note</strong>
              <p>
                Our privacy policy is subject to change at any time without
                notice. To make sure you are aware of any changes, please review
                this policy periodically.
              </p>
              <p>
                By visiting this Website you agree to be bound by the terms and
                conditions of this Privacy Policy. If you do not agree please do
                not use or access our Website.
              </p>
              <p>
                By mere use of the Website, you expressly consent to our use and
                disclosure of your personal information in accordance with this
                Privacy Policy. This Privacy Policy is incorporated into and
                subject to the Terms of Use.
              </p>
            </div>

            {data && data.map((item) => {
              return <div className="privacyBody">
                <h6>
                  1. {item.title}
                </h6>
                <p>
                  <strong>{item.description} </strong>
                  doing so we collect personal information
                  from you that we consider necessary for achieving this purpose.
                </p>
                <p>
                  Additionally, you may encounter "cookies" or other similar
                  devices on certain pages of the Website that are placed by third
                  parties. We do not control the use of cookies by third parties.
                </p>

              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
