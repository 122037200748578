export const MockData = {
    data: [
        {
            title: "Need help?",
            list: [
                { value: "Ask for help" },
                { value: "Conditions" },
                { value: "exchange" },
                { value: "To take off" },
                { value: "Delivery" },
                { value: "Program Vulnerability Disclosure" },
            ]
        },

        {
            title: "Tool Online",
            list: [
                { value: "Who are we?" },
                { value: "Business Solutions" },
                { value: "Sustainability" },
                { value: "Privacy declaration" },
                { value: "Legal information" },
                { value: "Cookie statement" },
                { value: "Stores" },
                // { value: "Digital Flyer" },
                // { value: "Press" },
                // { value: "Jobs" },
                // { value: "Complaints procedure for human rights and the environment" },
            ]
        },

        // {
        //     title: "At your service",
        //     list: [
        //         { value: "Our services" },
        //         { value: "Warranty & insurance" },
        //         { value: "Repair" },
        //         { value: "Trade-in service" },
        //         { value: "financing" },
        //         { value: "Energy services" },
        //         { value: "Security alarm system" },
        //         { value: "Telecommunication Solutions" },
        //     ]
        // },

        // {
        //     title: "Tool Online app",
        //     list: [
        //         { value: "Download in de Google PlayStore" },
        //         { value: "Download in de Apple App Store" },
        //     ]
        // },
   
    ]
}