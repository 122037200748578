import React from "react";
import { Helmet } from "react-helmet";
import { useGetTermsConditionQuery } from "../products/productSlice";

function TermsOfUse() {

  const { data, isLoading } = useGetTermsConditionQuery()
  console.log(data);
  return (
    <>
      <Helmet>
        <title>Terms & Condition | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <section className="termsSec">
        <div className="container">
          <div className="termsInfo">
            {isLoading && <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}
            <h4>Terms of Service</h4>
            <p>
              Please read the following terms and conditions very carefully as
              your use of service is subject to your acceptance of and
              compliance with the following terms and conditions ("Terms").
            </p>
            <p>
              By subscribing to or using any of our services you agree that you
              have read, understood and are bound by the Terms, regardless of
              how you subscribe to or use the services. If you do not want to be
              bound by the Terms, you must not subscribe to or use our services.
              These Terms and various other policies are binding as per the
              provisions of the Information Technology (Intermediaries
              guidelines) Rules, 2011 formulated under the Information
              Technology Act of 2000.
            </p>
            <p>
              In these Terms, references to "you", "User" shall mean the end
              user accessing the Website, its contents and using the Services
              offered through the Website, "Service Providers" mean independent
              third party service providers, and "we", "us" and "our" shall mean
              Vector E-commerce Private Limited and its affiliates.
            </p>
            <h6>1. Introduction: {data?.meta_title}</h6>

            <p>
             <strong>{data?.description}</strong>, a company incorporated under the laws of India.
            </p>

          

          </div>
        </div>
      </section>
    </>
  );
}

export default TermsOfUse;
