import React from 'react';
import blog1 from "../../../assets/img/product-detail/fertilizer-application1.jpg";
import blog2 from "../../../assets/img/product-detail/fertilizer-application-bg1.jpg"
import { FiChevronRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { useGetBlogCatQuery } from '../../products/productSlice';

function BlogAside() {
  const { data, isLoading } = useGetBlogCatQuery()
  return (
    <>
      <div className="blogAsideSec">
        <div className="blogCategory  ">
          <h4 className="asideTitle">blog category</h4>
          <hr />
          <ul>
            {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}

            {data && data.map((item) => {
              return <li key={item._id}><Link to="/"><FiChevronRight />{item.name}</Link></li>
            })}

          </ul>
        </div>
        <div className="recentPosts  ">
          <h4 className="asideTitle">recent posts</h4>
          <hr />
          <div className="recentItemInfo">
            <div className="recentImg">
              <img src={blog1} alt="Latest Blog" />
            </div>
            <div className="recentContent">
              <h6><Link to="/blog-detail">Top New Collection</Link></h6>
              <p className='date'>February 26, 2018</p>
            </div>
          </div>
          <div className="recentItemInfo  ">
            <div className="recentImg">
              <img src={blog2} alt="Latest Blog" />
            </div>
            <div className="recentContent">
              <h6><Link to="/blog-detail">Fashion Trends</Link></h6>
              <p className='date'>July 09, 2015</p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default BlogAside