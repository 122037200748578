import React from "react";
import { HiViewGrid } from "react-icons/hi";
import { FaBars } from "react-icons/fa";
import "./CollectionFilter.css";
function CollectionFilter({ handleList, handleGrid, totalProductLength ,getDataShort}) {

  return (
    <>
      <div className="collectionFilter">
        <div className="totalProducts">
          <h6>{totalProductLength?.length} products</h6>
          {/* <h6>{data?.length} products</h6> */}
        </div>
        <div className="collectionFilterItem">
          <div className="collectionFilterSort">
            <select defaultValue={'DEFAULT'} onChange={getDataShort}>
              {/* <option value="DEFAULT">  Default Sorting </option>
              <option value="manual">Featured</option>
              <option value="best-selling">Best selling</option> */}
              <option value="3"> Ascending by Name</option>
              <option value="4">Descending by Name</option>
              {/* <option value="price-ascending">Price, low to high</option>
              <option value="price-descending">Price, high to low</option> */}
              <option value="1">Newly added last</option>
              <option value="2">Newly added first </option>
            </select>
          </div>
          <div className="collectionFilterList">
            <ul className="no-bullets inline-list text-right">

              <li>
                <button
                  type="button"
                  className="grid-view-btn"
                  data-view="small"
                  title="Small"
                  onClick={handleGrid}
                >
                  <HiViewGrid />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="grid-view-btn"
                  data-view="list"
                  title="List"
                  onClick={handleList}
                >
                  <FaBars />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectionFilter;
