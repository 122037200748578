import React from "react";

function AboutChoose() {
  return (
    <>
      <section className="featuresSection p-30">
        <div className="container">
          <h3 className="subtitle mb-3 text-capitalize">why choose us</h3>
          <div className="row">
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-shipped" />
                <div className="feature-box-content">
                  <h5>Free Shipping</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industr.
                  </p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-us-dollar" />
                <div className="feature-box-content">
                  <h5>100% Money Back Guarantee</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industr.
                  </p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-online-support" />
                <div className="feature-box-content">
                  <h5>Online Support 24/7</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industr.
                  </p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </section>
    </>
  );
}

export default AboutChoose;
