import React from "react";

function DeliveryPolicy() {
  return (
    <>
      <section className="deliverySec p-30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="deliveryInfo">
                <div className="deliveryTitle mb-3">
                  <h1>Delivery Policy</h1>
                </div>

                <div className="deliveryNote mb-2">
                  <strong className="d-block mb-2">DELIVERY POLICY</strong>
                  <strong className="d-block">
                    **YOUR ORDER SHOULD BE DELIVERED WITHIN 5-7 WORKING DAYS **{" "}
                  </strong>
                </div>
                <div className="deliveryBody">
                  <p>
                    Delivery timelines are indicative and, on some occasions,
                    there might be some delay due to unavoidable circumstances."
                    If delivery or performance deadlines are not met for reasons
                    beyond our control, such as force majeure or any other
                    unforeseen event", that affects our component suppliers, the
                    agreed deadlines shall be extended for a reasonable period.
                    If there are multiple items, delivery might take some time.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DeliveryPolicy;
