import React from 'react'
import Breadcrumb from '../../shared/breadcrumb/Breadcrumb'
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy";

function PrivacyPolicyPage() {
  return (
    <>
        <Breadcrumb title="Privacy Policy" />
        <PrivacyPolicy />
    </>
  )
}

export default PrivacyPolicyPage