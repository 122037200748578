import React from "react";

function AboutStory() {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">
          <h3 className="subtitle">OUR STORY</h3>
          <p  className="lead">
            ETG has developed into a global player with a diverse portfolio of expertise across multiple industries, encompassing Agricultural inputs, logistics, merchandising and processing, supply chain optimization, digital transformation and energy.
          </p>
          <p>
            Our passion lies in the upliftment of farming communities; an unhindered commitment since establishment. Our promoters identified three challenges that supply chains in Africa faced and created a globalized business model around solutions addressing these obstacles.
          </p>
          {/* <p>
            “ Many desktop publishing packages and web page editors now use
            Lorem Ipsum as their default model search for evolved over sometimes
            by accident, sometimes on purpose ”
          </p> */}
        </div>
      </div>
    </>
  );
}

export default AboutStory;
