import React from "react";
import blog1 from "../../../assets/img/product-detail/fertilizer-application1.jpg";
import blog2 from "../../../assets/img/product-detail/fertilizer-application-bg1.jpg";
import { Link, useParams } from "react-router-dom";
import { useGetRevireBlogQuery, useSendLinkeMutation } from "../../products/productSlice";

function BlogUsersComment() {
  const params = useParams()
  const { data } = useGetRevireBlogQuery(params.id)
  const [sendLink, { isLoading }] = useSendLinkeMutation()

  const SendLinke = (id) => {
    sendLink(id)
  }

  return (
    <>
      <div className="blogComment p-30">
        <div className="comment-option">
          <h4>{data?.length} Comments</h4>
          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}

          {data && data.map((item) => {
            return <div className="single-comment-item first-comment">
              <div className="sc-author">
                <img src={blog1} alt="Blog" className="img-fluid" />
              </div>
              <div className="sc-text">
                <span>{item.createdAt}</span>
                <h5>{item.name}</h5>
                <p>
                  {item.review}
                </p>
                <button className={`comment-btn ${item.like_count === 1 && 'activeLike'}`} onClick={() => SendLinke(item._id)}>
                  Like
                </button>
                <Link to="/" className="comment-btn">
                  Reply
                </Link>
              </div>
            </div>
          })}

        </div>
      </div>
    </>
  );
}

export default BlogUsersComment;
