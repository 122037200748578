import { AiOutlineSearch } from "react-icons/ai"
import { Link } from "react-router-dom"

function KnowledgeBase() {
    return <>
        <div className="container">
            <div className="row">
                <div className="col-12 cusCol">
                    <h2>How can we help?</h2>
                    <input className="inputSearch form-control" placeholder="Search.." />
                    <AiOutlineSearch className="iconsRole" />
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div>
                        <h1>Uniware Support Articles</h1>
                        <hr />
                        <div className="insideBox">
                            <div>
                                <h3>Flipkart Troubleshooting/FAQs</h3>
                                <ul>
                                    <li>
                                        <Link to='detail'>Flipkart Channel Connector Broken</Link>
                                    </li>
                                    <li>
                                        <Link to='detail'>   My Flipkart Inventory is not updating / syncing</Link>
                                    </li>
                                    <li>My Flipkart Catalog is not updating / syncing</li>
                                </ul>
                                <hr />
                            </div>
                            <div>
                                <h3>New Packing Flow</h3>
                                <ul>
                                    <li>Staging and Bundling Workflow</li>
                                    <li>Enforced Shipment and Bundle Sorting</li>
                                    <li>Configuration Driven Shipment Actions</li>
                                </ul>
                                <hr />
                            </div>
                        </div>
                        <div className="insideBox">
                            <div>
                                <h3>Flipkart Troubleshooting/FAQs</h3>
                                <ul>
                                    <li>Flipkart Channel Connector Broken</li>
                                    <li>My Flipkart Inventory is not updating / syncing</li>
                                    <li>My Flipkart Catalog is not updating / syncing</li>
                                </ul>
                                <hr />
                            </div>
                            <div>
                                <h3>New Packing Flow</h3>
                                <ul>
                                    <li>Staging and Bundling Workflow</li>
                                    <li>Enforced Shipment and Bundle Sorting</li>
                                    <li>Configuration Driven Shipment Actions</li>
                                </ul>
                                <hr />
                            </div>
                        </div>
                        <div className="insideBox">
                            <div>
                                <h3>Flipkart Troubleshooting/FAQs</h3>
                                <ul>
                                    <li>Flipkart Channel Connector Broken</li>
                                    <li>My Flipkart Inventory is not updating / syncing</li>
                                    <li>My Flipkart Catalog is not updating / syncing</li>
                                </ul>
                                <hr />
                            </div>
                            <div>
                                <h3>New Packing Flow</h3>
                                <ul>
                                    <li>Staging and Bundling Workflow</li>
                                    <li>Enforced Shipment and Bundle Sorting</li>
                                    <li>Configuration Driven Shipment Actions</li>
                                </ul>
                                <hr />
                            </div>
                        </div>
                        <div className="insideBox">
                            <div>
                                <h3>Flipkart Troubleshooting/FAQs</h3>
                                <ul>
                                    <li>Flipkart Channel Connector Broken</li>
                                    <li>My Flipkart Inventory is not updating / syncing</li>
                                    <li>My Flipkart Catalog is not updating / syncing</li>
                                </ul>
                                <hr />
                            </div>
                            <div>
                                <h3>New Packing Flow</h3>
                                <ul>
                                    <li>Staging and Bundling Workflow</li>
                                    <li>Enforced Shipment and Bundle Sorting</li>
                                    <li>Configuration Driven Shipment Actions</li>
                                </ul>
                                <hr />
                            </div>
                        </div>
                        <div className="insideBox">
                            <div>
                                <h3>Related Resources</h3>
                                <ul>
                                    <li>Related Resources</li>
                                </ul>
                                <hr />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <section id="ht-kb-articles-widget-2" className="widget hkb_widget_articles">
                        <h3 className="widget__title">Popular Articles</h3>
                        <ul>
                            <li className="hkb-widget-article__format-standard">
                                <Link
                                    className="hkb-widget__entry-title"
                                    to='#'
                                >
                                    Log in to Uniware with your Mobile Number/Gmail id and reset password
                                    with OTP for Login
                                </Link>
                            </li>
                            <li className="hkb-widget-article__format-standard">
                                <Link
                                    className="hkb-widget__entry-title"
                                    to='#'
                                >
                                    Purchase Orders and Goods Received Note (GRN)
                                </Link>
                            </li>
                            <li className="hkb-widget-article__format-standard">
                                <Link
                                    className="hkb-widget__entry-title"
                                    to='#'
                                >
                                    Barcode Printer Integration
                                </Link>
                            </li>
                            <li className="hkb-widget-article__format-standard">
                                <Link
                                    className="hkb-widget__entry-title"
                                    to='#'
                                >
                                    Q: How to link SKUs in bulk or mapping of listing through import
                                </Link>
                            </li>
                            <li className="hkb-widget-article__format-standard">
                                <Link
                                    className="hkb-widget__entry-title"
                                    to='#'
                                >
                                    Integration with Myntra PPMP
                                </Link>
                            </li>
                        </ul>
                    </section>
                    <section id="ht-kb-exit-widget-2" className="widget hkb_widget_exit">
                        <h3 className="widget__title">Need Support?</h3>
                        <div className="hkb_widget_exit__content" style={{ color: "white" }}>
                            Can't find the answer you're looking for? Don't worry we're here to help!
                        </div>
                        <Link
                            className="hkb_widget_exit__btn"
                            to='#'
                            target="_blank"
                            rel="nofollow"
                        >
                            RAISE SUPPORT TICKET
                        </Link>
                    </section>

                </div>
            </div>
        </div>
    </>
}
export default KnowledgeBase