import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import etg from "../../assets/img/brands/etg.png";
import kynoch from "../../assets/img/brands/kynoch.png";
import falcon from "../../assets/img/brands/falcon.png";
import oemff from "../../assets/img/brands/oemff.png";
import PrivacyPolicy from "../../common/privacy-policy/PrivacyPolicy";
import { BrandImg } from "../home/proImgs/Brandimg";
import { Helmet } from "react-helmet";
import { useListAllBrandQuery } from "../products/productSlice";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

function ViewAllBrand() {
  const [data, setData] = useState(null)


  const getData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/brand/public`)
      setData(res.data);
    } catch (error) {
      alert('Server Error')
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Helmet>
        <title>Brands | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Breadcrumb title="All Brands" />
      <section className="brandsSec bg-white">
        {/* {isLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>} */}
        <div className="container">
          <div className="brandsItem fullBrandsItem">
            <div className="brandsHeader">
              <div className="fisherman-content mb-4">
                <span>our brands</span>
                <h3>top brands</h3>
              </div>
            </div>

            <ul>
              {data && data.map((item, i) => {
                console.log(item);
                return <li key={item._id}>
                  <Link to={`/product/brand/${item._id}`}>
                    <img src={item?.logo?.url ? item?.logo?.url : BrandImg[i]?.url} alt="Brand" className="img-fluid" />
                  </Link>
                </li>
              })}

            </ul>
          </div>
        </div>
      </section>
      <PrivacyPolicy />
    </>
  );
}

export default ViewAllBrand;
