import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";

import "./Header.css";
// import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";

function Header() {
  const [isSticky, setIsSticky] = useState(false);


  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true)
    }
    else {
      setIsSticky(false);
    }
  }
  window.addEventListener("scroll", fixedHeader);


  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  })

  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/adminFrontMessage/id`)
    setState({
      front_top_message: res.data.front_top_message,
      icon: res.data.icon,
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>

      <header className={` ${isSticky ? "sticky active" : "sticky"}`}>
        <TopHeader state={state} />
        <SearchBox val={state} />
        <section className="navbarArea">
          <div className="abaris-nav">
            <div className="container-flued">
              <nav className="navbar navbarItem navbar-expand-md navbar-light">
                {/* <Menus /> */}

                <section className="Section__StyledSection-sc-381c97c3-0 glTYGq">
                  <div className="USPBar-styled__StyledContainer-sc-178732e2-0 ixVhpQ">
                    <ul className="USPBar-styled__StyledList-sc-178732e2-1 dVRbXd">
                      <li className="USPBarEntry-styled__StyledListItem-sc-d9325dc1-0 jTkaLP">
                        <span
                          fontFamily="default"
                          color="#ffffff"
                          fontSize="xs"
                          className="sc-hLBbgP hrQbZo"
                        >
                          <a
                            className="Link-styled__StyledLinkAnchor-sc-db43135e-0 cBQQQp"
                            target="_self"
                            href="#"
                            color="#ffffff"
                          >
                            <span
                              fontFamily="default"
                              color="#ffffff"
                              fontSize="xs"
                              className="sc-hLBbgP hrQbZo sc-ksBlkl jagznR"
                            >
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  Pick up within 30 minutes at your Tool Online
                                </font>
                              </font>
                            </span>
                          </a>
                        </span>
                      </li>
                      <li className="USPBarEntry-styled__StyledListItem-sc-d9325dc1-0 jTkaLP">
                        <span
                          fontFamily="default"
                          color="#ffffff"
                          fontSize="xs"
                          className="sc-hLBbgP hrQbZo"
                        >
                          <a
                            className="Link-styled__StyledLinkAnchor-sc-db43135e-0 cBQQQp"
                            target="_self"
                            href="#"
                            color="#ffffff"
                          >
                            <span
                              fontFamily="default"
                              color="#ffffff"
                              fontSize="xs"
                              className="sc-hLBbgP hrQbZo sc-ksBlkl jagznR"
                            >
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>Tool Online</font>
                              </font>
                            </span>
                          </a>
                        </span>
                      </li>
                      <li className="USPBarEntry-styled__StyledListItem-sc-d9325dc1-0 jTkaLP">
                        <span
                          fontFamily="default"
                          color="#ffffff"
                          fontSize="xs"
                          className="sc-hLBbgP hrQbZo"
                        >
                          <a
                            className="Link-styled__StyledLinkAnchor-sc-db43135e-0 cBQQQp"
                            target="_self"
                            href="#"
                            color="#ffffff"
                          >
                            <span
                              fontFamily="default"
                              color="#ffffff"
                              fontSize="xs"
                              className="sc-hLBbgP hrQbZo sc-ksBlkl jagznR"
                            >
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  Free delivery from €50
                                </font>
                              </font>
                            </span>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </section>






              </nav>
            </div>
          </div>
        </section>
      </header>
    </>
  );
}

export default Header;
