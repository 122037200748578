import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai';
import { IoMdArrowDropright } from 'react-icons/io';
import { Link } from 'react-router-dom';
import img1 from "../../../assets/left1.webp"

function MenuListCatagary(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='cusModals'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3 className='martMark'>Tool Online</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <h6 style={{ fontWeight: "700" }}>All Catagary</h6>
                <hr />


                <div className='mainMenusd'>
                    <ul className='ulMenus' style={{ padding: 0 }}>



                        <li className='upeerSep'>
                            <Link to='products' >
                                {/* <img style={{ marginRight: "6px" }} src={img1} /> */}
                                <span>Agriculture Garden & Landscaping</span>
                                <AiOutlinePlus />
                            </Link>
                            <div className='sepSide'>
                                <ul>
                                    <li>
                                        <span>Harvester</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                    <li>
                                        <span>Grain Processing Machine</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                    <li> 
                                        <span>Chaff Cutter</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                    <li>
                                        <span>Harvester</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                    <li>
                                        <span>Grain Processing Machine</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                    <li> 
                                        <span>Chaff Cutter</span>
                                        <AiOutlinePlusCircle />
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <span> Power Tools</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Office Supplies</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Material Handling and Packaging</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Electrical</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Furniture, Hospitality and Food Service</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Pumps</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Automotive Maintenance and Lubricants</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Hand Tools</span>
                            <AiOutlinePlus />
                        </li>
                        <li>
                            <span> Cleaning</span>
                            <AiOutlinePlus />
                        </li>
                    </ul>

                </div>


            </Modal.Body>
        </Modal>
    );
}
export default MenuListCatagary