import React from 'react'
import Breadcrumb from '../../shared/breadcrumb/Breadcrumb'
import BlogAside from './blog-aside/BlogAside'
import BlogItem from './blog-item/BlogItem'

function Blog() {
  return (
    <>
        <Breadcrumb title="Blog" />
        <section className='blogSec'>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <BlogItem />
                    </div>
                    <div className="col-md-4">
                        <BlogAside />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Blog