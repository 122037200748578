import React from "react";

function ContactInfo() {
  return (
    <>
      <section className="contactContainer p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contactInfo">
                <h4 className="ls-n-25 m-b-1">Contact Info</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  imperdiet libero id nisi euismod, sed porta est consectetur.
                  Vestibulum auctor felis eget orci semper vestibulum.
                  Pellentesque ultricies nibh gravida, accumsan libero luctus,
                  molestie nunc.L orem ipsum dolor sit amet, consectetur
                  adipiscing elit.
                </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactInfo;
