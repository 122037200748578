import img1 from "../../../assets/prductsImg/ETG M601 - 5KG-min.png"
import img2 from "../../../assets/prductsImg/ETG M601 - 10KG-min.png"
import img3 from "../../../assets/prductsImg/ETG M401-min.png"
import img4 from "../../../assets/prductsImg/ETG M401 - 5KG-min.png"
import img5 from "../../../assets/prductsImg/ETG M401 - 10KG-min.png"
import img6 from "../../../assets/prductsImg/EGGPLANT BLACK BEAUTY-min.png"
import img7 from "../../../assets/prductsImg/CUCUMBER BETH ALPHA-min.png"
import img8 from "../../../assets/prductsImg/CARROT NANTES 2-min.png"
import img9 from "../../../assets/prductsImg/CABBAGE COPENHAGEN MARKET 2-min.png"

export const ImgesData = [
    { url: img1 },
    { url: img8 },
    { url: img7 },
    { url: img6 },
    { url: img5 },
    { url: img2 },
    { url: img4},
    { url: img9},
    { url: img3},
]