import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import ContactAddress from "./contact-address/ContactAddress";
import ContactForm from "./contact-form/ContactForm";
import ContactInfo from "./contact-info/ContactInfo";
import ContactMap from "./contact-map/ContactMap";

function Contact() {
  return (
    <>
      <Breadcrumb title="Contact" />
      <main className="main">
        <ContactMap />
        <ContactInfo />
        <ContactAddress />
        <ContactForm />
      </main>
    </>
  );
}

export default Contact;
